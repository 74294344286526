// importing components
import BusinessSuite from "../components/BusinessSuite/BusinessSuite";
import Button from "../components/Button/Button";
import Call2Action from "../components/Call2Action/Call2Action";
import Footer from "../components/Footer/Footer";

//importing page styling
import "./Pages.scss";

//importing image
import sewageTreatmentPlant from "../images/sewage-treatment-plant.jpeg";

export default function AboutUs() {
  return (
    <>
      <div className="about-us">
        <h1>We are ABS Sewage Digester Brand</h1>
        <p>
          A hybrid business model with a holistic and technologically driven
          mindset for sustainable and environmental friendly housing projects.
        </p>
        <a href="tel:2348036427288">
          <Button buttonText="Call Now" />
        </a>
      </div>
      <BusinessSuite />
      <div className="about-us-div">
        <img
          src={sewageTreatmentPlant}
          alt="treatment plant"
          style={{
            width: "100%",
          }}
        />
        <div className="about-us-section">
          <h4>Other services we offer:</h4>
          <ul className="other-service-list">
            <li>Electrical installation & Service </li>
            <li>Installation of authomatic garage door or gate opener</li>
            <li>Installation of solar power</li>
            <li>Domestic biogas system</li>
            <li>Dislodgement of old septic tank</li>
          </ul>
        </div>
      </div>
      <Call2Action />
      <Footer />
    </>
  );
}
