//importing homepage components
import Hero from "../components/Hero/Hero";
import BusinessSuite from "../components/BusinessSuite/BusinessSuite";
import BriefBackground from "../components/BriefBackground/BriefBackground";
import Features from "../components/Features/Features";
import Call2Action from "../components/Call2Action/Call2Action";
import Footer from "../components/Footer/Footer";

export default function Homepage() {
  return (
    <>
      <Hero />
      <BusinessSuite />
      <BriefBackground />
      <Features />
      <Call2Action />
      <Footer />
    </>
  );
}
