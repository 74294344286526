//importing state manaagement for the nav links
import { useState } from "react";

//importing scss for the header component
import "./header.scss";

//importing react-icons
import { FiMenu } from "react-icons/fi";

//import react link library from react-router-dom
import { Link } from "react-router-dom";

// importing brand logo
import brandLogo from "../../images/abs-biodigester-logo.png";

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <header>
        <div className="head">
          <Link to="/">
            <img
              src={brandLogo}
              alt="abs biodigester"
              style={{ width: "74px", height: "64x" }}
            />
          </Link>
          <FiMenu className="menu" onClick={handleClick} />

          <nav className={openMenu ? "navLink-expanded" : "navLink"}>
            <Link to="/" className="anchor">
              Home
            </Link>
            <Link to="/about-us" className="anchor">
              About Us
            </Link>
            <Link to="/faqs" className="anchor">
              FAQs
            </Link>
            <Link to="/contact-us" className="anchor" id="contactUs">
              Contact Us
            </Link>
          </nav>
        </div>
      </header>
    </>
  );
}
