// importing components
import Call2Action from "../components/Call2Action/Call2Action";
import Footer from "../components/Footer/Footer";

// importing image
import toiletSeat from "../images/toilet-seat.jpeg";
import sketch from "../images/bio-digester-sketch.png";

// importing react-accordion
import Faq from "react-faq-component";

//importing page styling
import "./Pages.scss";

export default function Faqs() {
  const data = {
    title: "FAQs",
    rows: [
      {
        title: "How do bio digesters work?",
        content: `<p>Bio digesters eliminate toilet waste water odors by over powering the natural occurring bacteria mostly found in septic tanks and man holes.
        For example, in biofil digesters, the waste water from the flush unit (water closets) is organically filtered through a porous slab (Filter material), allowed to penetrate a small rock-filled soak hole from which it leaches into the soil.
        </p>
        <br />
        <p>
        In places where there are gutters, the black water is organically filtered out of the bottom of the digester and drained into them.
        Bio degradable toilet waste digesters have established themselves as the modern-day answer to pit latrine and septic tanks as toilet treatment systems.
        </p>
        `,
      },
      {
        title: "Where does the waste water from a bio digester go?",
        content: `<p>There are a lot of misconceptions around bio digesters as an effective toilet waste water management system.
          One of the most asked questions is where does the flushed water from a water closet goes.
          Usually, people are not sure how such a small box can contain all these waters and serve a whole household without getting full.
          </p>
          <br />
          <p>
          But because bio digester toilets are used as part of the process of treating bio degradable toilet waste water and sewage sludge, the process often referred to as anaerobic digestion is adopted.
          Natural composting organic manure are introduced to treats all organic bio degradable materials through the natural process of anaerobic decomposition.
          Flushed water and solid waste enter at the top of the bio digester where immediate separation of solids and liquid contents of the waste occurs.
          </p>
          <br />
          <p>
          All liquids will then be organically filtered out of the bottom of the digester and drained into the soil where further and final decomposition occurs. 
          Other solids (toilet rolls & all degradable cleaning materials) are decomposed and converted into rich & safe soil for easy disposal. Now the final effluent (waste water) 
          produced is a clear odorless liquid suitable for discharge either into a waste watercourse, through a customized soak hole trenches or a drain filled system.
          </p>
          `,
      },
      {
        title: "Why should I choose bio-digester toilet?",
        content: `<p>Even though bio digester toilets cost has come up lately for public discussions, there’s no doubt they have become the choice for most home owners in most places today.
        Whiles there is no concrete research available that shows the exact percentage currently adopting this waste water treatment method, there is no doubt that they are effectively helping to help improve the sanitation situation in most homes.
        </p>
        <br />
        <p>
        Another reason being that digester did not take much space, with minimum of 3 by 3 feet size of land digester box that will accommodate three-bedroom flat of family size of 4 person will be installed.
        </p>`,
      },
      {
        title: "Is bio digester internationally approved and recognized?",
        content: `Not only do bio digester toilets in Lagos State have governmental approval (you can check from the nearest State and Country), they have also received worldwide acceptance by the World Bank and UNICEF. 
        Simply put our bio digesters are safe for everyone; private homes, offices and public needs.`,
      },
    ],
  };

  const styles = {
    arrowColor: "green",
  };

  const config = {
    animate: true,
    tabFocus: true,
  };

  return (
    <>
      <img
        className="FaqsImg"
        src={toiletSeat}
        alt="toilet seat"
        style={{ width: "100%", objectFit: "cover", height: "430px" }}
      />
      <div className="Faqs">
        <div id="overlay"></div>
        <div className="Faqs-text">
          <h1>Frequently Asked Question</h1>
          <p>Below are some of the most frequently asked questions.</p>
        </div>
        <div className="main">
          <Faq
            style={{ marginTop: "500px", width: "100%" }}
            data={data}
            styles={styles}
            config={config}
          />
          <img
            src={sketch}
            alt="sewage digester sketch"
            style={{ width: "100%", height: "400px", objectFit: "cover" }}
          />
        </div>
      </div>
      <Call2Action />
      <Footer />
    </>
  );
}
