import "./Call2Action.scss";
import Button from "../Button/Button";

export default function Call2Action() {
  return (
    <>
      <div className="Call2Action">
        <h4>Get Started Today!</h4>
        <p>
          Talk to us to learn more and get started with your very own
          bio-digester
        </p>
        <div className="buttons">
          <a href="tel:2348036427288">
            <Button buttonText="Call Now" />
          </a>
          <a
            href="https://wa.me/+2348036427288?text=I'm%20interested%20in%20the%20sewage%20digester"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonText="WhatsApp" />
          </a>
        </div>
      </div>
    </>
  );
}
