import "./App.scss";
// import Underconstruction from "./Underconstruction";

// import react router libraries
import {
  createRoutesFromElements,
  Route,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//importing layout
import RootLayout from "./layouts/RootLayout";

// importing pages
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Faqs from "./pages/Faqs";
import ContactUs from "./pages/ContactUs";
import ErrorPage from "./pages/ErrorPage";

function App() {
  // creating react Browser Router
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
        <Route index element={<Homepage />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="faqs" element={<Faqs />} />
        <Route path="contact-us" element={<ContactUs />} />
      </Route>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
