import "./Footer.scss";
import brandLogo from "../../images/Abs-sewage-digester-logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="Footer">
        <div className="groupFooter">
          <img
            src={brandLogo}
            alt="abs sewage digester logo"
            style={{ width: "74px", height: "54px" }}
          />
          <div className="groupFooterItem">
            <div className="address footer-item">
              <h4>Office address: </h4>
              <p>
                28, Imo Shopping Complex, Opposite Ogun Radio, Abeokuta, Ogun
                State.
              </p>
            </div>

            <div className="footer-item">
              <h4>Quick links: </h4>
              <nav>
                <Link to="/" className="footerLinks">
                  Home{" "}
                </Link>
                <Link to="/about-us" className="footerLinks">
                  About Us{" "}
                </Link>
                <Link to="/faqs" className="footerLinks">
                  FAQs{" "}
                </Link>
                <Link to="/contact-us" className="footerLinks">
                  Contact Us{" "}
                </Link>
              </nav>
            </div>

            <div className="contact-footer footer-item">
              <h4>Quick contact: </h4>
              <div>
                <p>
                  Email:{" "}
                  <a href="mailto:abs.digester@gmail.com">
                    abs.digester@gmail.com
                  </a>
                </p>
                <p>
                  <a href="mailto:josepholumuyiwah@gmail.com">
                    josepholumuyiwah@gmail.com
                  </a>
                </p>
              </div>

              <p>Call or WhatsApp: 08079916432, 08036427288 </p>
            </div>
          </div>

          <div className="copyright">
            <h4>
              Owned and operated by{" "}
              <a
                href="https://www.canva.com/design/DAGKnBZakS8/VJzzFFFciCY4oKmM9J24Yg/edit?utm_content=DAGKnBZakS8&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                className="link-style"
                rel="noreferrer"
                target="_blank"
              >
                Joas-Noble Services Ltd 2024
              </a>{" "}
              | Built by{" "}
              <Link
                to="https://olanrewajubalogun.netlify.app/"
                target="_blank"
                rel="noreferrer"
                className="link-style"
              >
                Olanrewaju{" "}
              </Link>{" "}
              | All rights reserved
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
