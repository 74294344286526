import "./Features.scss";

// import react icons
import { FaEnvira } from "react-icons/fa";
import { VscSymbolStructure } from "react-icons/vsc";
import { SiWorldhealthorganization } from "react-icons/si";
import { HiCurrencyDollar } from "react-icons/hi";

export default function Features() {
  const style = {
    fontSize: "50px",
  };
  return (
    <div className="Features">
      <h3>Features {"/"} Benefits of ABS Sewage Digester</h3>

      <div className="groupFeature">
        <div>
          <div className="eachFeature">
            <FaEnvira style={style} />
            <h4>Eco-friendly Technology</h4>
            <p>
              Environmentally safe and highly improved means of treating toilet
              waste water in worldwide today.
            </p>
          </div>
          <div className="eachFeature">
            <VscSymbolStructure style={style} />
            <h4>Spacial Efficiency</h4>
            <p>
              The bio-digester is take up less space in your architecture
              leaving you with room for more structure on your land.
            </p>
          </div>
        </div>

        <div>
          <div className="eachFeature">
            <SiWorldhealthorganization style={style} />
            <h4>Internationally Approved</h4>
            <p>
              The food and agricultural organisation of United Nation recognises
              biodigesters as a sustainable alternative to waste management.
            </p>
          </div>

          <div className="eachFeature">
            <HiCurrencyDollar style={style} />
            <h4>Cost Effective</h4>
            <p>
              They are cheaper to install (as compare to digging a manhole or
              buying culverts for septic sewage tanks).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
