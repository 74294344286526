// import react error component
import { useRouteError } from "react-router-dom";

// importing components
import Header from "../components/Header/header";
import Footer from "../components/Footer/Footer";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <Header />
      <div className="error-page">
        <div className="error-page-item">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
