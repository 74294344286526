// importing styling
import "./Hero.scss";

// importing components
import Button from "../Button/Button";

/// importing images
import biodigester1 from "../../images/Biodigester1.jpeg";
import biodigester2 from "../../images/Biodigester2.jpeg";
import biodigester3 from "../../images/Biodigester3.jpeg";
import biodigester4 from "../../images/Technician_working_on_Biodigester.jpeg";

export default function Hero() {
  const images = [
    {
      id: 1,
      img: biodigester1,
      alt: "biodigester img",
      class: "one",
    },
    {
      id: 2,
      img: biodigester2,
      alt: "biodigester img2",
      class: "two",
    },
    {
      id: 3,
      img: biodigester4,
      alt: "biodigester img3",
      class: "three",
    },
    {
      id: 4,
      img: biodigester3,
      alt: "biodigester img4",
      class: "four",
    },
  ];

  function imageList() {
    return images.map((image) => (
      <img
        className={image.class}
        key={image.id}
        src={image.img}
        alt={image.alt}
      />
    ));
  }

  return (
    <>
      <div className="hero">
        <div className="heroSection">
          <h1>Modern SOAK AWAY system that doesn’t get full.</h1>
          <p>
            Globally recognized sewage technology that suits your needs.
            Suitable for homes, schools, offices, complexes, malls etc.
          </p>
          <a href="tel:2348036427288">
            <Button buttonText="Call Now" />
          </a>
        </div>
        <div className="image-container">{imageList()}</div>
      </div>
    </>
  );
}
