import React from "react";

export default function Button(props) {
  const buttonStyle = {
    backgroundColor: "var(--primary-color)",
    color: "white",
    padding: "10px 25px",
    borderRadius: "25px",
    border: "none",
  };
  return (
    <div>
      <button style={buttonStyle} href={props.href}>
        {props.buttonText}
      </button>
    </div>
  );
}
