import React from "react";
import "./BusinessSuite.scss";

// importing react icons
import { MdOutlineConstruction } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { BsCalendarCheckFill } from "react-icons/bs";

export default function BusinessSuite() {
  return (
    <>
      <div className="BusinessSuite">
        <div className="item1">
          <MdOutlineConstruction style={{ fontSize: "50px" }} />
          <h4>Bio digester projects</h4>
          <p>50</p>
        </div>
        <div className="item2">
          <RiCustomerService2Fill style={{ fontSize: "50px" }} />
          <h4>Our clients</h4>
          <p>20</p>
        </div>
        <div className="item3">
          <BsCalendarCheckFill style={{ fontSize: "50px" }} />
          <h4>Years of experience</h4>
          <p>5</p>
        </div>
      </div>
    </>
  );
}
