import "./BriefBackground.scss";
import img from "../../images/bio-digester-new.gif";

export default function BriefBackground() {
  return (
    <>
      <div className="briefBackground">
        <h2>Bio-digester: A brief overview & history</h2>
        <div className="briefContainer">
          <img
            style={{
              width: "100%",
              height: "300px",
              objectFit: "cover",
              margin: "0 0 45px",
            }}
            src={img}
            alt="bio digester"
          />
          <div>
            <p>
              The Bio-Digester Waste Management System is an easy to install
              structure that for the treatment of human waste. It is an
              eco-friendly technology that is helping to meet the Millennium
              Development Goals (MDG-7) and actively supporting the vision of an
              open defecation free society.
            </p>
            <br></br>
            <p>
              The history of bio digesters in Ghana is not clear but they became
              prominent after the 2014 severe cholera outbreak in some parts of
              Accra. The Government and the World Bank then decided that
              considering the sanitation situation especially in low-income
              households in Accra, bio digester toilets were the best option to
              curb the spread of cholera. Mr. Kweku Anno, owner of Biofilcom,
              designed and built the first concept of a toilet system that
              functions independent of sewerage systems and septic tanks now
              called the BIOFILTM system.
            </p>
            <br></br>
            <p>
              This digester-based system was developed around two major
              components: a filtering system, which separates the solid from the
              liquid waste and the use of micro-organisms for the breakdown of
              the solid waste. Since then, bio digesters as an integrated toilet
              waste water management system is no longer peculiar to Ghana. It
              can be found in all part of the world due to its eco-friendliness
              and effectiveness.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
