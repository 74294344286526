// importing react-icons
import { ImLocation } from "react-icons/im";
import { BsFillClockFill, BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

// state manamgement
import { useRef } from "react";
import emailjs from "@emailjs/browser";

//importing page styling
import "./Pages.scss";

import telephone from "../images/telephone-contact-us.jpeg";

// importing component
import Footer from "../components/Footer/Footer";

export default function ContactUs() {
  // generic style object for contact info elements
  const style = {
    fontSize: "25px",
    margin: "10px",
  };

  // handling form submission
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_SERVICE_KEY}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_USER_ID}`
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="contact-us">
        <div className="contact">
          <img
            src={telephone}
            alt="telephone-contact-us"
            style={{
              width: "100%",
              marginBottom: "40px",
              height: "500px",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="contact-flex-container">
          <div className="contact-info">
            <div className="contact-us-text">
              <h1>Contact us</h1>
              <p>
                Speak with us about your needs. Feel free to ask any question.
              </p>
            </div>

            <div className="contact-info-flex-container">
              <div className="contact-info-flex-item">
                <div className="location">
                  <ImLocation style={style} />
                  <h4>Address</h4>
                  <p>
                    No. 28, Imo Shopping Complex Opposite Ogun Radio Abeokuta.
                    Ogun State
                  </p>
                </div>
                <div className="openingHours">
                  <BsFillClockFill style={style} />
                  <h4>Opening Hours</h4>
                  <p>Monday - Friday: 9am - 5pm</p>
                  <p>Saturday: 2pm - 5pm</p>
                  <p>Sunday: Closed</p>
                </div>
              </div>

              <div className="contact-info-flex-item">
                <div className="emails">
                  <MdEmail style={style} />
                  <h4>Emails</h4>
                  <p>abs.digester@gmail.com, josepholumuyiwah@gmail.com</p>
                </div>
                <div className="telephone">
                  <BsTelephoneFill style={style} />
                  <h4>Telephone</h4>
                  <p>WhatsApp: 08036427288</p>
                  <p>Mobile: 08079916432, 08036427288</p>
                </div>
              </div>
            </div>
          </div>

          <div className="message">
            <div className="message-us">
              <h2>Drop a line for us</h2>
              <p>
                If you prefer, you can drop a line of message for us and we'll
                get to you ASAP.
              </p>
            </div>

            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-item">
                <label htmlFor="name">Name:</label>
                <input type="text" name="username" />
              </div>
              <div className="form-item">
                <label htmlFor="email">Email:</label>
                <input type="email" name="user_email" />
              </div>
              <div className="form-item">
                <label htmlFor="">Message:</label>
                <textarea type="text" name="message" id="message"></textarea>
              </div>
              <input type="submit" value="submit" />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
